import Vue from 'vue'
// import axios from '@/libs/axios'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import VueSticky from 'v-sticky';
// import i18n from '@/libs/i18n'

// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// import Echo from 'laravel-echo'
// window.Pusher = require('pusher-js')

import router from './router'
import store from './store'
import App from './App.vue'
import "./interceptors"
import "./middlewares"
import utils from "@/libs/utils";
import infiniteScroll from 'vue-infinite-scroll'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueMoment from 'vue-moment';

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '4dfe86d7fc92947ff96f',
  cluster: 'mt1',
  forceTLS: false,
  encrypted: false,
})

// Configura el cliente de Pusher
// window.Pusher = Pusher;
// Pusher.logToConsole = true;

import 'moment/locale/es'; // Importar el idioma español de Moment.js si es necesario

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import '@fortawesome/fontawesome-free/css/all.css'

// Global Components
import './global-components'

// 3rd party plugins
// import '@/libs/axios'
// import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js')
//       .then(registration => {
//         console.log('Service Worker registrado con éxito:', registration.scope);
//       })
//       .catch(error => {
//         console.error('Error al registrar el Service Worker:', error);
//       });
//   });
// }

Vue.component('qr-code', VueQRCodeComponent)


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueMoment); // Usar el plugin vue-moment

Vue.use(infiniteScroll)

// Sticky
// Vue.use(VueSticky);

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}`;
// Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.mixin(utils);

//
const MAX_INACTIVITY_TIME = 30 * 60 * 1000; // 30 minutos
let secondsRemaining = MAX_INACTIVITY_TIME / 1000; // Convertir milisegundos a segundos
let timer;

function resetTimer() {
  clearTimeout(timer);
  secondsRemaining = MAX_INACTIVITY_TIME / 1000; // Reiniciar contador de segundos
  timer = setInterval(() => {
    secondsRemaining--; // Decrementar el contador de segundos
    if (secondsRemaining <= 0) {
      
      clearInterval(timer); // Detener el temporizador cuando el tiempo se agote
      console.log('Inactividad detectada');

      //Validamos si existe un usuario logueado
      if (store.getters['auth/getIsAuthenticated']) {
        console.log('Cerrando sesión por inactividad');
        store.dispatch('auth/LOGOUT');
        router.push({ name: 'login' });
      }      
      
    } else {
      console.log(`Segundos restantes: ${secondsRemaining}`);
    }
  }, 1000); // Actualizar cada segundo
}

// Vue.mixin({
//   mounted() {
//     console.log('Iniciando temporizador de inactividad')
//     resetTimer();
//     window.addEventListener('click', resetTimer);
//     window.addEventListener('keypress', resetTimer);
//     window.addEventListener('scroll', resetTimer);
//   },
// });

// Directiva para forzar un salto de página
Vue.directive('page-break', {
  inserted(el) {
    el.style.pageBreakAfter = 'always';
    el.style.marginTop = '5mm';
  }
});

Vue.directive('page-break-prices', {
  inserted(el) {
    el.style.pageBreakAfter = 'always';
    el.style.marginTop = '10mm';
  }
});

Vue.directive('page-break-with-header', {
  inserted(el) {
    el.style.pageBreakBefore = 'always';
    const headerClone = document.querySelector('.pdf-header').cloneNode(true);
    el.parentNode.insertBefore(headerClone, el);
    el.style.marginTop = '50mm';
  }
});


new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
  created() {
    // window.Echo.channel('chat')
    // .listen('.NewMessage', async (data) => {
    //   console.log('new message received')
    //   console.log(data)
    // })
  }
}).$mount('#app')
