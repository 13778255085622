import axios from '@/libs/axios'
export default class CatalogClassifiers {

    /**
     * Function to Clasificadores_Cat
     * @return Promise
     */
    index() {
        return axios.get(`/ClasificadoresCat/ObtenerClasificadores`);
    }

    findByCompanyId(idEmpesa) {
        return axios.get(`/ClasificadoresCat/ObtenerPorIdEmpresa/${idEmpesa}`);
    }

    findValuesByClassifierId(id) {
        return axios.get(`/ClasificadoresCatValores/ObtenerClasificadoresCatValoresPorClasificadorId/${id}`)
    }

    getByCompanyIdAndClassifierId(idEmpresa, idClasificador) {
        return axios.get(`/ClasificadoresCatValores/ObtenerPorIdEmpresaYClasificador/${idEmpresa}/${idClasificador}`);
    }
}