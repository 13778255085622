import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import axios from "@/libs/axios";
import * as Cookies from "js-cookie";
import store from "./store";

const excludePaths = ["login", "/login", "/forgot", "/reset", "/refresh_token"];

// Función para verificar si el token ha expirado
const isTokenExpired = (token) => {
    if (!token) return true; // Si no hay token, lo consideramos caducado

    try {
        const decodedToken = jwtDecode(token); // Decodificamos el token
        const currentTime = Date.now() / 1000; // Obtenemos el tiempo actual en segundos

        // console.log("Token expires at:", new Date(decodedToken.exp * 1000).toISOString());
        // console.log("Current time:", new Date(currentTime * 1000).toISOString());
        return decodedToken.exp < currentTime; // Verificamos si ha caducado
    } catch (e) {
        console.error("Error decoding token:", e);
        return true; // Si hay un error al decodificar, lo consideramos caducado
    }
};

// Interceptor for requests
axios.interceptors.request.use(
    (config) => {
        config.headers = config.headers || {};
        if (!_.includes(excludePaths, config.url)) {
            const token = localStorage.getItem("auth_token");
            // console.log("Token:", token); // Debug token

            // Validar si el token ya ha expirado
            if (isTokenExpired(token)) {
                console.log("Token has expired, dispatching token expiration");
                // store.dispatch("auth/UPDATE_TOKEN_EXPIRED");
                // return Promise.reject("Token expired");
            }

            config.headers["Content-Type"] = "application/json";
            config.headers["X-Requested-With"] = "XMLHttpRequest";
            config.headers["Access-Control-Allow-Origin"] = "*";
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (err) => {
        console.error("Request Error:", err);
        return Promise.reject(err);
    }
);

// Helper function for handling response statuses
const responseStatuses = (err) => {
    // If there is no response from the server
    if (!err.response) {
        console.error("No response from server:", err); // Debugging network or server issues
        // Dispatch token expired action if network fails or no response from server
        store.dispatch("auth/UPDATE_TOKEN_EXPIRED");
        return Promise.reject(err);
    }

    console.log("Handling error status:", err.response.status); // Debugging

    if (_.includes([409, 404], err.response.status)) {
        console.error("Error 409 or 404:", err);
        throw err;
    } else if (err.response.status === 401 || err.response.status === 403) {
        console.log("Token expired or unauthorized access detected");
        Cookies.remove("vuex");
        // Dispatching the action to update token expiration
        store.dispatch("auth/UPDATE_TOKEN_EXPIRED");
        return Promise.reject(err); // Make sure we reject the promise after handling the error
    } else {
        console.error("Other Error:", err);
        return Promise.reject(err);
    }
};

// Interceptor for responses
axios.interceptors.response.use(
    (response) => response,
    (err) => {
        // Check if response.config exists to avoid errors
        if (err.response && err.response.config) {
            if (!_.includes(excludePaths, err.response.config.url)) {
                return responseStatuses(err);
            }
        } else {
            return responseStatuses(err);
        }
    }
);


// import { jwtDecode } from "jwt-decode";
// import axios from "@/libs/axios";
// import * as Cookies from "js-cookie";
// import store from "./store";
// import _ from "lodash";
// import router from "./router";

// const excludePaths = ["login", "/login", "/forgot", "/reset", "/refresh_token"];

// // Función para verificar si el token ha expirado
// const isTokenExpired = (token) => {
//     if (!token) return true; // Si no hay token, lo consideramos caducado

//     try {
//         const decodedToken = jwtDecode(token); // Decodificamos el token
//         const currentTime = Date.now() / 1000; // Obtenemos el tiempo actual en segundos

//         console.log("Token expires at:", new Date(decodedToken.exp * 1000).toISOString());
//         console.log("Current time:", new Date(currentTime * 1000).toISOString());        
//         return decodedToken.exp < currentTime; // Verificamos si ha caducado
//     } catch (e) {
//         console.error("Error decoding token:", e);
//         return true; // Si hay un error al decodificar, lo consideramos caducado
//     }
// };

// const handleTokenExpired = () => {
//     Cookies.remove("vuex");
//     store.dispatch("auth/UPDATE_TOKEN_EXPIRED");

//     // Verificamos si ya estamos en la ruta de login antes de redirigir
//     if (router.currentRoute.path !== "/login") {
//         router.push("/login").catch((err) => {
//             if (err.name !== "NavigationDuplicated") {
//                 console.error(err);
//             }
//         });
//     }
// };

// // Interceptor para las solicitudes
// axios.interceptors.request.use(
//     (config) => {
//         config.headers = config.headers || {};
//         if (!_.includes(excludePaths, config.url)) {
//             const token = localStorage.getItem("auth_token");

//             // Validar si el token ya ha expirado
//             if (isTokenExpired(token)) {
//                 console.log("Token has expired, dispatching token expiration");
//                 handleTokenExpired();
//                 return Promise.reject("Token expired");
//             }

//             // Si el token es válido, lo incluimos en las cabeceras
//             config.headers["Content-Type"] = "application/json";
//             config.headers["X-Requested-With"] = "XMLHttpRequest";
//             config.headers["Access-Control-Allow-Origin"] = "*";
//             if (token) {
//                 config.headers.Authorization = `Bearer ${token}`;
//             }
//         }
//         return config;
//     },
//     (err) => {
//         console.error("Request Error:", err);
//         return Promise.reject(err);
//     }
// );

// // Interceptor para las respuestas
// axios.interceptors.response.use(
//     (response) => response,
//     (err) => {
//         if (!err.response) {
//             console.error("No response from server:", err);
//             handleTokenExpired(); // Manejar error sin respuesta del servidor
//             return Promise.reject(err);
//         }

//         if (_.includes([401, 403], err.response.status)) {
//             console.log("Token expired or unauthorized access");
//             handleTokenExpired(); // Token caducado o acceso no autorizado
//         }

//         return Promise.reject(err);
//     }
// );




// import _ from "lodash";
// import axios from "@/libs/axios";
// import * as Cookies from "js-cookie";
// import store from "./store";

// const excludePaths = ["login", "/login", "/forgot", "/reset", "/refresh_token"];
// // Interceptor for requests

// axios.interceptors.request.use(
//     (config) => {
//         config.headers = config.headers || {};
//         if (!_.includes(excludePaths, config.url)) {
//             const token = localStorage.getItem("auth_token");
//             config.headers["Content-Type"] = "application/json";
//             config.headers["X-Requested-With"] = "XMLHttpRequest";
//             config.headers["Access-Control-Allow-Origin"] = "*";
//             config.headers.Authorization = `Bearer ${token}`;
//             // config.headers["Accept-Language"] = store.getters.getLocale.substring(0, 2); // Workaround for translate on backend
//         }
//         return config;
//     },
//     (err) => Promise.reject(err)
// );

// const responseStatuses = (err) => {
//     if (_.includes([409, 404], err.response.status)) {
//         console.log("Error: ", err);
//         throw err;
//     // } else if ( (err.response.status === 401 || err.response.status === 403) /*&& err.response.config && !err.response.config.__isRetryRequest*/) {
//     } else if (err.response && (err.response.status === 401 || err.response.status === 403)) {
//         console.log("Token expired");
//         Cookies.remove("vuex");
//         store.dispatch("auth/UPDATE_TOKEN_EXPIRED");
//     } else {
//         console.log("Error: ", err);
//         return Promise.reject(err);
//     }
//     console.log("Error: ", err);
//     throw err;
// };

// // Interceptor for responses
// axios.interceptors.response.use(
//     (response) => response,
//     (err) => {
//         if (err.response.config) {
//             if (!_.includes(excludePaths, err.response.config.url)) {
//                 return responseStatuses(err);
//             }
//         } else {
//             return responseStatuses(err);
//         }
//     }
// );
