import axios from '@/libs/axios'

export default class Attributes {
        
    /**
    * Function to attributes
    * @return Promise
    */
    index(query) {
        return axios.get(`/Articulos/ObtenerAtributos`, { params: query });
    }
}